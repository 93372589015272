$( document ).on('turbolinks:load', function() {
  $('#new_role_form').bind('ajax:error', function(event) {
    let responseText = event.originalEvent.detail[0];
    responseText = responseText.replace('["','').replace('"]','').split('","')

    $('.error-messages').html('');

    responseText.forEach( response => {
      $('.error-messages').append(`<p>${response}</p>`)
    })

    if (responseText.length){
      $('.alert.alert-danger.alert-modal').show();
    }
  });
});