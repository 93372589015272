import { chapters,credit_approval_memorandum_chapters,generateMessageContext } from "./chaptersQuestion";
$(document).on("turbolinks:load", function () {
  $(".close").on("click", function (e) {
      $(".chatgptModalContainer").addClass("d-none");
  });
  $(".userQuestionInput").keydown(function (e) {
    if (e.key === "Enter") { // Check if the Enter key is pressed (key code 13)
      e.preventDefault(); // Prevent the default behavior (line break)
      var currentText = $(this).val(); // Get the current text in the textarea
      var newText = currentText + "\n"; // Add a line break
      $(this).val(newText); // Set the updated text in the textarea
    }
  });
});
function addChatLoader(index) {
  $("#chatModalBody" + index).append(`
    <div class="d-flex flex-row justify-content-start mb-4 pt-1 chat-gpt">
        <div class="ic-chatgpt-icon"></div>
        <div class="d-flex flex-row chatSpinner ">
            <div class="spinner-grow" style="width: 0.5rem; height: 0.5rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow" style="width: 0.5rem; height: 0.5rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow" style="width: 0.5rem; height: 0.5rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    `);
    
    
    const chatBody = document.getElementById(`chatModalBody${index}`)
    chatBody.scrollTop = chatBody?.scrollHeight
}
window.fetchAllChats = function (document_type,description, company, index, prompt_data) {
  let children = $("#chatModalBody" + index).children();
  let chats = [];
  let question = getQuestion(document_type,description,index)
  let context = generateMessageContext(company?.replace(/["']/g,""),JSON.stringify(question),prompt_data)
  chats = [...context]
  children?.map((message_index, child) => {
    let className = child?.className;
    let pTag = child?.getElementsByTagName("p")[0];
    let message = pTag?.innerHTML;
    if (className?.includes("chat-gpt")) {
      chats.push({
        role: "assistant",
        content: message,
      });
    } else if (message_index&&className?.includes("chat-user")) {
      chats.push({
        role: "user",
        content: message,
      });
    }
  });
  addChatLoader(index);
  callOpenAi({
    chatArray: chats,
  },index);
};
window.callOpenAi = function (postData,index) {
  $.ajax({
    type: "POST",
    url: "/chatnow/ask", // URL to your Rails route
    data: postData,
    success: function (response) {
      $(".chatSpinner").replaceWith(`
        <div class="credit-buddy-response chat-message-body">
            <div>
                <p class="small p-2 me-3 mb-1 text-white rounded-3 bg-secondary chat">${response}</p>
            </div>
            <div class="chat-copy-btn" onclick="copyChatgptResponse(this)">
                <span >Copy</span>
                <div class="fa fa-clone chat-copy-icon">
                </div>
            </div>
        </div>
    `);
    
    const chatBody = document.getElementById(`chatModalBody${index}`)
    chatBody.scrollTop = chatBody?.scrollHeight
    if(index){
      let element = document.getElementById("sendQuestionBtn"+index)
      element.disabled = false
      element?.classList?.remove("disabledBtn")
    }
    },
    error: function (xhr, status, error) {
      alert("Error in getting response");
      console.error("POST request failed:", status, error);
      $(".chatSpinner").replaceWith(`
            <div>
                <p class="small p-2 me-3 mb-1 text-white rounded-3 bg-secondary chat">${error?.message}</p>
            </div>
      `)
    if(index){
      let element = document.getElementById("sendQuestionBtn"+index)
      element.disabled = false
      element?.classList?.remove("disabledBtn")
    }
    }
  });
};

window.copyChatgptResponse = function (element) {
  const prevElement = element?.previousElementSibling;
  if (prevElement) {
    const r = document.createRange();
    const copyBtnChild = element.children[0];
    copyBtnChild.innerHTML = "Copied";
    element?.classList?.add("active");
    r.selectNode(prevElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    setTimeout(function () {
      element?.classList?.remove("active");
      copyBtnChild.innerHTML = "Copy";
    }, 2000);
  }
};
window.getQuestion = function (document_type,question, index) {
  if (question) {
    switch( document_type){
      case "Credit Approval Memorandum":
        return credit_approval_memorandum_chapters[question][index];
      case "Credit Memorandum":
        return chapters[question][index];
      default:
        return;
    }
  }
  return ;
};
window.sendQuestion = function (element,document_type, index, description, company, prompt_data) {
  let question = getQuestion(document_type,description, index);

  let chatArray = generateMessageContext(company?.replace(/["']/g,""),JSON.stringify(question),prompt_data)
  let postData = {
    chatArray: chatArray,
  };
  element?.nextElementSibling?.classList?.remove("d-none");
  $("#chatgptModal" + index).modal("show");
  if ($("#chatModalBody" + index).children().length === 0) {
    callOpenAi(postData,index);
    $("#chatModalBody" + index).append(`
      <div class="chat-user" style="display:none;">
        <div class="chat-message-body">
            <p class="small p-2 me-3 mb-1 text-white rounded-3 bg-primary chat">${question?.question}</p>
        </div>
    </div>`);
    addChatLoader(index);
    if(index){
      let element = document.getElementById("sendQuestionBtn"+index)
      element.disabled = true
      element?.classList?.add("disabledBtn")
    }
  }
};

window.sendQuestionBtnFn = function (document_type,description, company, index, prompt_data) {
  var value = $("#userQuestionInput"+index).val();
  $("#userQuestionInput"+index).val("");
  if (value&&value?.trim()!="") {
    $("#chatModalBody" + index).append(`
          <div class="d-flex flex-row justify-content-end mb-4 pt-1 chat-user">
            <div class="chat-message-body">
                <p class="small p-2 me-3 mb-1 text-white rounded-3 bg-primary chat">${value}</p>
            </div>
            <div class="ic-user-icon"></div>
        </div>`
      );
    const chatBody = document.getElementById(`chatModalBody${index}`)
    chatBody.scrollTop = chatBody?.scrollHeight
    if(index){
      let element = document.getElementById("sendQuestionBtn"+index)
      element.disabled = true
      element?.classList?.add("disabledBtn")
      fetchAllChats(document_type,description,company.replace(/["']/g,""), index, prompt_data);
    }
  }
};
