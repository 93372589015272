$( document ).on('turbolinks:load', function() {
  window.progressBar = function() {
    let documents = document.querySelectorAll('.progress-bar-document')
    if (documents) {
      documents.forEach((doc) => {
        let document_id = doc.dataset['id']
        fetch(`/documents/${document_id}/progress.json`)
          .then(response => response.json())
          .then((data) => {
            doc.style.width = `${Math.round(data*100)}%`;
            document.querySelector(`.sidebar_complete[data-id='${document_id}']`).innerHTML = `${Math.round(data*100)}%`;
          });

        if ($('.progress-bar-chapter').length) {
          fetch(`/documents/${document_id}/chapter_progress`)
            .then(response => response.json())
            .then((data) => {
              data.forEach((result) => {
                document.querySelector(`.progress-bar-chapter[data-id='${result.chapter_id}']`).style.width = `${result.count*100}%`;
                document.querySelector(`.chapter-progress-number[data-id='${result.chapter_id}']`).innerHTML = `${Math.round(result.count*100)}%`;
                if (result.count == 1){
                  let progress_bar = document.querySelector(`.progress-bar-chapter[data-id='${result.chapter_id}']`)
                  $(progress_bar).parents('.chapter_card').addClass('chapter_complete')
                } else {
                  let progress_bar = document.querySelector(`.progress-bar-chapter[data-id='${result.chapter_id}']`)
                  $(progress_bar).parents('.chapter_card').removeClass('chapter_complete')
                }
              })
            });
        }

      })
    }
  }

  function userDropdown() {
    $('#user_actions').dropdown()
    $('.dropdown-active').dropdown()
  }

  userDropdown();
  
  $('.date-input-field').on('input',function(event){
    let inputValue = event.target.value.replace(/\D/g, '');

    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2)}`;
    }
    if (inputValue.length > 5) {
      inputValue = `${inputValue.slice(0, 5)}/${inputValue.slice(5, 9)}`;
    }
    event.target.value = inputValue;

  })
});