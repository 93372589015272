import React, { useState, useEffect } from 'react'
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';

import TableForm from 'components/TableForm';
import RowButtons from 'components/RowButtons';

const Report = (props) => {
	const [table, setTable] = useState( { grid: [] });

  function getFinancialReportId() {
    return props.id
  }

  async function fetchState() {
    if (props.id != 0 && props.id != undefined) {
      const financial_report_id = getFinancialReportId();
      const columns_fetch = await fetch(`/api/v1/financial_reports/${financial_report_id}`);
      columns_fetch
        .json()
        .then(response => { setTable(response) });
    }
  };

  async function updateTable(grid) {
    const financial_report_id = getFinancialReportId();
    await fetch(`/api/v1/financial_reports/${props.id}`, {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({table: grid}),
    })
  };

	useEffect(() => {
     fetchState()
	 }, [props]);

  async function addRow(event) {
    event.preventDefault();
    const financial_report_id = getFinancialReportId();
    await fetch(`/api/v1/financial_reports/${financial_report_id}/add_row`, {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
    })
      .then(response => { return response.json() })
      .then(response => { props.fetchTable() })
      .catch(error => { console.log(error) })
  }

  async function removeRow(event) {
    event.preventDefault();
    const financial_report_id = getFinancialReportId();
    await fetch(`/api/v1/financial_reports/${financial_report_id}/remove_row`, {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
    })
      .then(response => { return response.json() })
      .then(response => { props.fetchTable() })
      .catch(error => { console.log(error) })
  }

  const cellChanged = changes => {
    const grid = table.grid.map(row => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      grid[row][col] = { ...grid[row][col], value };
    });
    setTable({ grid });
    updateTable(grid);
  }

  return (
  	<div className="financial-report-container">
      <ReactDataSheet
        data = { table.grid }
        valueRenderer = { cell => cell.value }
        onCellsChanged = { cellChanged }
      />
      { table.grid.length != 0 && 
        <RowButtons 
          addRow={addRow.bind(this)}
          removeRow={removeRow.bind(this)}
        />
      }
    </div>		
  );
}

export default Report;
