$( document ).on('turbolinks:load', function() {
  if ($('#share_container').length){
    window.progressBar();

    function bindDocumentCard() {
      $('.document-card').on('click', function() {
        $('.document-card').removeClass('focus-document');
        $(this).addClass('focus-document');

        // $('p.button-text').addClass('hidden');
        // let document_id = $(this).data('document-id');
        // $(`p.button-text[data-document-id='${document_id}']`).removeClass('hidden');
      });
    }

    function filterDocuments() {
      let document_id =  $('#company_select_share').val();
      let document_notes = $('#document_notes').val();
      fetch(`/documents/filtered_documents?company_id=${document_id}&notes=${document_notes}`)
        .then(response => response.text())
        .then((data) => {
          $('#document_cards').html(data);
          window.progressBar();
          bindDocumentCard();
        });
    }

    function bindEvents() {
      $('#company_select_share').on('change', () => filterDocuments());
      $('#document_notes').on('keyup', () => filterDocuments());
      bindDocumentCard();
    }

    bindEvents();
  }
});