import React from 'react'

const RowButtons = ( props ) => {

	return (
		<div>
      <button className="btn btn-xs primary-button" onClick={props.addRow}>
        Add Row
      </button>
      <button className="btn btn-xs danger-button" onClick={props.removeRow}>
        Remove Row
      </button>
    </div>
	);
}

export default RowButtons;
