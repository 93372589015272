$(document).on('turbolinks:load', function() {

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function generateFirstAmortizationTable(data) {
        var html = '<table class="table table-bordered">';
        html += '<thead class="thead" style="background-color: #495057;color:white;"><tr><th>Action</th><th>Date</th><th>Amount</th><th>Number</th><th>Period</th><th>End Date</th></tr></thead>';
        html += '<tbody>';
    
        // First row for Lease
        html += '<tr>';
        html += '<td>' + 'Lease' + '</td>';
        html += '<td>' + (data?.lease_start_date ? data.lease_start_date : '') + '</td>';
        html += '<td>' +'$'+ (data?.loan_amount ? numberWithCommas(data?.loan_amount) : '') + '</td>';
        html += '<td>' + (data?.amortization_schedule[0]?.month ? data?.amortization_schedule[0]?.month : '') + '</td>';
        html += '<td></td>';
        html += '<td ></td>';
        html += '</tr>';
    
        // Second row for Lease Payment
        html += '<tr>';
        html += '<td>Lease Payment</td>';
        html += '<td>' + (data?.amortization_schedule[0]?.date ? data?.amortization_schedule[0]?.date : '') + '</td>';
        html += '<td>' +'$'+(data?.amortization_schedule[0]?.monthly_payment ?  numberWithCommas(data?.amortization_schedule[0]?.monthly_payment) : '') + '</td>';
        html += '<td>' + (data?.period_in_months ? data?.period_in_months : '')  + '</td>';
        html += '<td>Monthly</td>';
        html += '<td >' + (data?.amortization_schedule[data?.amortization_schedule.length - 1]?.date ? data?.amortization_schedule[data?.amortization_schedule.length - 1]?.date : '') + '</td>';
        html += '</tr>';
    
        html += '</tbody>';
        html += '</table>';
        return html;
    }

    function generateAmortizationTable(data,totals) {
        var html = '<table class="table table-bordered">';
        html += '<thead class="thead" style="background-color: #495057;color:white;"><tr><th>Month</th><th>Date</th><th>Lease Payment</th><th>Interest Payment</th><th>Principal Payment</th><th>Loan Balance</th></tr></thead>';
        html += '<tbody>';
        for (var i = 0; i < data.length; i++) {
            html += '<tr >';
            html += '<td class="small-padding">' + data[i].month + '</td>';
            html += '<td class="small-padding">' + (data[i].date ? data[i].date  : '') + '</td>';
            html += '<td class="small-padding">' +'$'+(data[i].monthly_payment ? numberWithCommas(data[i].monthly_payment.toFixed(2)) : '') + '</td>';
            html += '<td class="small-padding">' +'$'+ (data[i].interest_payment ? numberWithCommas(data[i].interest_payment.toFixed(2)) : '') + '</td>';
            html += '<td class="small-padding">' +'$'+(data[i].principle_payment ? numberWithCommas(data[i].principle_payment.toFixed(2)) : '') + '</td>';
            html += '<td class="small-padding">' +'$'+ numberWithCommas(data[i].loan_balance.toFixed(1)) + '</td>';
            html += '</tr>';
        }

        html += '<tr style="font-weight: bold;">';
        html += '<td colspan="2" class="small-padding">Grand Total</td>';
        html += '<td class="small-padding">' +'$'+ numberWithCommas(totals.total_monthly_payment.toFixed(2)) + '</td>';
        html += '<td class="small-padding">' +'$'+numberWithCommas(totals.total_interest_payment.toFixed(2)) + '</td>';
        html += '<td class="small-padding">' +'$'+numberWithCommas(totals.total_principle_payment.toFixed(2)) + '</td>';
        html += '<td class="small-padding"></td>'; 
        html += '</tr>';
        
        html += '</tbody>';
        html += '</table>';
        return html;
    }

    $('#calculate-button').on('click', function() {
        const loanAmount = $('[name="deal_amortization[loan_amount]"]').val();
        const interest = $('[name="deal_amortization[interest]"]').val();
        const periodInMonths = $('[name="deal_amortization[period_in_months]"]').val();
        const leaseStartDate = $('[name="deal_amortization[lease_start_date]"]').val();

        $.ajax({
            url: '/deal_amortizations/calculate',
            type: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            data: JSON.stringify({
                loan_amount: loanAmount,
                interest: interest,
                period_in_months: periodInMonths,
                leaseStartDate:leaseStartDate
            }),
            success: function(data) {
                $('[name="deal_amortization[monthly_interest]"]').val(data.monthly_interest);
                $('[name="deal_amortization[monthly_payment]"]').val(numberWithCommas(data.monthly_payment));
                $('#amortization-schedule').html(generateAmortizationTable(data.amortization_schedule,data));
                $('#first-amortization-schedule').html(generateFirstAmortizationTable(data));
                $('#Upload-amortization-button').removeClass('hidden');
                $('#first-table-amortization-heading').removeClass('hidden');
                $('#second-table-amortization-heading').removeClass('hidden');
            },
            error: function(error) {
                console.error('Error:', error);
            }
        });
    });

    $('#deal-amortization-dropdown').on('change', function() {
        var value = $(this).val();
        var reportId = $('#report_id').val();
        
        redirectUser(value, reportId);
    });

    function redirectUser(value, report_id) {
        if (value == 'new-amortization-form') {
            window.location.href = window.location.origin + "/deal_amortizations/new";
        } else if (value == 'edit-Amortization-form' && report_id != 'not found') {
            window.location.href = window.location.origin + `/deal_amortizations/${report_id}/edit`;
        } else if (value == 'view-all') {
            window.location.href = window.location.origin + "/deal_amortizations?view=true";
        } else {
            $(".report-dropdown").val("choose-form");
            alert("No Recent EVF Report Found");
        }
    }
function viewAmort(){
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('home_page') && urlParams.get('home_page') === 'true') {
        const calculateButton = document.getElementById('calculate-button');
        
        if (calculateButton) {
            calculateButton.click();
        }
        $('.update-report-btn').css('display', 'none');
        $('#hide-content-view').css('display', 'none');

    }
}
viewAmort()    
});
