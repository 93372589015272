import { setCompanyForm,resetCompanyForm } from "./new_report";

$( document ).on('turbolinks:load', function() {

  let chapters_list = document.getElementById('sidebar_cards');
  let update_chapter_order = document.getElementById('update_chapter_order');

  if ($(update_chapter_order).length && $(update_chapter_order).val() == 'true'){
    if ($(chapters_list).length){
      Sortable.create(chapters_list, {
        animation: 150,
        chosenClass: 'selected-chapter',
        onEnd: (event) => {
          let new_order = []
          $('.chapter_card').each( (index, card) => {
            new_order.push($(card).data('id'));
          })

          let csrfToken = document.querySelector('meta[name="csrf-token"]').content

          fetch(`/chapters/update_order`, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': csrfToken
            },
            body: JSON.stringify({new_order: new_order})
          }).then(response => response.json())
            .then((data) => {
              console.log(data);
            });

        }
      })
    }
  }

  function getCompanyForm(document_id, company_id) {
    fetch(`/companies/company_form?company_id=${company_id}`)
      .then(response => response.text())
      .then((data) => {
        $(`.company-form-container[data-company-id='${company_id}']`).html(data)
        $(`.company-form-container[data-company-id='${company_id}']`).removeClass('hidden')
        if ($("div.template.active").data('name')==="Credit Approval Memorandum") 
          setCompanyForm()
        else
          resetCompanyForm()
      }).then(()=>{
        initializeSelect2()
      });
  };
  
  function initializeSelect2() {
    let company_settings_form = $("#company-setting-modal")
    let new_report_form = $("#newReportModal")
    if (company_settings_form.hasClass("show")){
      company_settings_form?.find(".company_industry")?.select2({
        tags:true,
        placeholder:"Please type the Industry name and press enter"
       })
      new_report_form?.find(".company-form-container")?.html("")
    }
    else if (new_report_form.hasClass("show")) {
      new_report_form?.find(".company_industry")?.select2({
        tags:true,
        placeholder:"Please type the Industry name and press enter"
       })
      company_settings_form?.find(".company-form-container")?.html("")      
    } 
  };
  
  function initializeHandlers() {
    $('#edit-document-button').on('click', function(event){
      event.preventDefault();
      $('#document-setting-modal').modal('show');
    });

    $('#edit-company-button').on('click', function(event){
      event.preventDefault();
      $('#company-setting-modal').modal('show');
    });

    $('.edit-button').on('click', function(event){
      event.preventDefault();
      $('.company-form-container').html('')
      let company_id  = $(this).data('company-id')
      let document_id = $(this).data('document-id')

      getCompanyForm(document_id, company_id);
    });

    $('.company-radio-button').on('click', function(){
      $('.edit-button').addClass('hidden')
      $('.delete-company-button').addClass('hidden')
      $('.company-form-container').html('')

      let document_id = $('.company-radio-button').val();
      if ($(this).val() == 0) {
        getCompanyForm(document_id, 0);
      }
      if ($(this).hasClass('editable')){
        $(this).parent().children('.edit-button').removeClass('hidden')
        $(this).parent().children('.delete-company-button').removeClass('hidden')
      }
    });

    $('.close-company-errors').on('click', function(event) {
      $('.alert.alert-danger.alert-modal.company-error-messages').hide();
    });

    $('.company-radio-button:checked').parent().children('.edit-button').removeClass('hidden')
    $('.company-radio-button:checked').parent().children('.delete-company-button').removeClass('hidden')
  };

  function ajaxCallbacks() {
    $('#document_settings_form').bind('ajax:error', function(event) {
      let responseText = event.originalEvent.detail[0];
      $('#document-error-messages').html('');
      responseText.forEach( response => {
        $('#document-error-messages').append(`<p>${response}</p>`)
      })
      if (responseText.length){
        $('.alert.alert-danger.alert-modal').show();
      }
    });

    $('.delete-company-button').bind('ajax:error', function(event) {
      let responseText = event.originalEvent.detail[0];
      $('#company-error-messages').html('');
      responseText.forEach( response => {
        $('#company-error-messages').append(`<p>${response}</p>`)
      })
      if (responseText.length){
        $('.alert.alert-danger.alert-modal.company-error-messages').show();
      }
    });
  };

  initializeHandlers();
  ajaxCallbacks();
});