$( document ).on('turbolinks:load', function() {
  function bindAjaxError() {
    $('#new_report_form').bind('ajax:error', function(event) {
      let responseText = event.originalEvent.detail[0];
      $('#new-report-error-messages').html('');
      $("div.new_report_overlay").removeClass("show");
      $("div.new_report_spinner").removeClass("show");

      if (responseText?.length){
        $('.alert.alert-danger.alert-modal.new-report-error-messages').show();
        responseText?.forEach( response => {
          $('#new-report-error-messages').append(`<p>${response}</p>`)
        })
      }
    });
  }
  
  $('.close-report-errors').on('click', function(event) {
    event.preventDefault();
    $('.alert.alert-danger.alert-modal.new-report-error-messages').hide();
  });
  
  $('#newReportModalClose').on('click', function(event) {
    event.preventDefault();
    $('#new-report-error-messages').html('');
    $('.alert.alert-danger.alert-modal.new-report-error-messages').hide();
  });
  function bindNewReportEvents() {
    $('#newReportModal').on('show.bs.modal', function () {
      let template = $('.template').first();
      template.addClass('active');
      $('#document_template_name').val(template.data('name'));
    })

    $('div.template').on('click', function(){
      $('div.template').removeClass('active');
      $(this).addClass('active');
      $('#document_template_name').val($(this).data('name'));
      if ($(this).data('name')==="Credit Approval Memorandum") 
        setCompanyForm()
      else
        resetCompanyForm()
      if ($(this).data('name')==="Four Blocker") 
        $("#draft_report_button").hide()
      else 
        $("#draft_report_button").show()
    });
  }

  $('#new_report_form').find('select#document_user_id').change(function() {
    $.get( "companies_for_user?user_id="+$( this ).val(), function( companies ) {
      $( "div[id^='company_id']" ).hide( "slow" );
      $.each(companies, function( index, value ) {
        $( "div#company_id_"+value ).show( "slow" );
      });
    });
  });
  $("#draft_report_button").on('click', function(){
    let condition = 0
    if ($('#new_report_form')[0]?.checkValidity()){
      $("div.new_report_overlay").addClass("show");
      $("div.new_report_spinner").addClass("show");
      let timer = setInterval(async function(){
        await fetch('/documents/draft_report_status')
        .then(resp=> resp.json())
        .then((resp)=>{
          if (resp?.message == "Drafting Complete") {
            clearTimeout(timer)
            window.location.href= resp?.redirect_path
          }
        })
        .catch(error=>{ 
          console.log("Error", error);
          window.location.reload();
        })
        if (condition>=15){ 
          clearTimeout(timer)
          window.location.reload()
        }
        condition +=1
      },20000)
    }
  })

  bindAjaxError();
  bindNewReportEvents();
});
export function setCompanyForm (){
  const company_name_field = $(':input[name="company[name]"]'),
  company_tax_id =  $(':input[name="company[tax_id]"]');
  company_name_field.attr('placeholder', 'Company Legal Name')
  company_tax_id.attr('placeholder','Federal Tax ID#')
}
export function resetCompanyForm(){
  const company_name_field = $(':input[name="company[name]"]'),
  company_tax_id =  $(':input[name="company[tax_id]"]');
  company_name_field.attr('placeholder', 'Company Name')
  company_tax_id.attr('placeholder','Tax Identification Number')
}