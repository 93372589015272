$( document ).on('turbolinks:load', function() {
  function bindAjaxError() {
    $('#company_settings_form').bind('ajax:error', function(event) {
      let responseText = event.originalEvent.detail[0];
      $('#company-error-messages').html('');

      if (responseText.length){
        $('.alert.alert-danger.alert-modal.company-error-messages').show();
        responseText.forEach( response => {
          $('#company-error-messages').append(`<p>${response}</p>`)
        })
      }
    });
  }

  $('.close-company-errors').on('click', function(event) {
    event.preventDefault();
    $('.alert.alert-danger.alert-modal.company-error-messages').hide();
  });

  bindAjaxError();
});
