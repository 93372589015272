$( document ).on('turbolinks:load', function() {
  $('.tip-link').on('click', function(event){
    event.preventDefault();
    let id = $(this).data('id');
    let name = $(this).data('name');

    fetch(`/questions/${id}/tip?name=${name}`)
      .then(response => response.text())
      .then((data) => {
        $('#tips-modal-container').html(data);
        $('#tipsModal').modal('show');
      });
  });
});