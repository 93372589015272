$(document).on('turbolinks:load', function() {
  $(document).on("change", '#user_role_id', function() {
    roles = ["Broker", "Broker Admin", "User"]
    if (roles.includes($('#user_role_id option:selected').text())) {
      $('#users_quota_container').show()
    } else {
      $('#users_quota_container').hide()
    }
  });

  $(".plan-radio-group input[type='radio']").on('change', function(){
    plan = $(this).val();
    $('.plan-type-title').text(plan.toUpperCase().replace("_", "-"));
    $('#user_plan_type').val(plan);
    $("#new_user").attr('action', `/users?plan=${plan}`);
    window.history.replaceState(null, null, `?plan=${plan}`);
  })

  $("input[name='user[website_url]']").on('change', function() {
    $(this).val($(this).val().toLowerCase());
  })

  $('#toggle-password').on('click', function() {
    togglePassword("user_password", '.show-password', '.hide-password');
  })

  $('#toggle-password-confirmation').on('click', function() {
    togglePassword("user_password_confirmation", '.show-password-confirmation', '.hide-password-confirmation');
  })

  $('#toggle-current-password').on('click', function() {
    togglePassword("user_current_password", '.show-current-password', '.hide-current-password');
  })

  function togglePassword(input, show, hide) {
    var input = document.getElementById(input);
    $(hide).hide();
    $(show).show();
    if (input.type === "password") {
      input.type = "text";
    } else {
      $(hide).show();
     $(show).hide();
      input.type = "password";
    }
  }
});
