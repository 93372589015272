$( document ).on('turbolinks:load', function() {

  function trixEditor() {
    var trix_editor = document.querySelectorAll("trix-editor")
    trix_editor.forEach(function(trix) {
      if (trix.attributes.value != undefined)
        trix.editor.insertHTML(trix.attributes.value.textContent)
    });

    if ($('.trix-button-group--file-tools').length){
      $('.trix-button-group--file-tools').remove();
      $('.trix-button--icon-code').remove();
    }
  }

  function documentsFunctions() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const download_pdf = urlParams.get('download_pdf');

    if (download_pdf != null && download_pdf.length && download_pdf == 'true') {
      const document_id = $('.progress-bar-document').data('id')
      window.open(`/documents/${document_id}/full_pdf.pdf`)
    }

    $('#create-document').on('click', function(event){
      event.preventDefault();

      let data = {
        company: $('#company').val(),
        document: $('#document').val(),
      }

      if (data.company.length && data.document.length){
        $('#create_document_form').submit();
      }
    });

    $('.sidebar-link').on('click', function(event){
      $('#question_form').submit();
    });

    $('#question_form').on('change', '.custom-file-input',function(event){
      let fileName = event.target.files[0].name;
      $(this).parent().children('.custom-file-label').html(fileName);
    });
  }

  function handdleToggle(element) {
    let name = $(element).data('name');
    if ($(element).prop('checked')){
      $(`div[data-section-name="${name}"]`).show();
      $(`div[data-section-name="${name}_file"]`).show();
    } else {
      $(`div[data-section-name="${name}"]`).hide();
      $(`div[data-section-name="${name}_file"]`).hide();
    }
  };

  function toggleEvents() {
    $('.required-toggle').each(function(index, element){
      handdleToggle(element);
    });

    $('.required-toggle').on('change', function(){
      handdleToggle(this);
    });
  };

  function initializeSelect2() {
    $('.select2').select2({
      tags: true
    });
  };

  if ($('#document_form_container').length){
    window.progressBar();
    documentsFunctions();
    trixEditor();
    toggleEvents();
    initializeSelect2();
  }
});