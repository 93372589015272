import React from "react";
import YearInputs from "components/YearInputs";

const TableForm = (props) => {
  const sendForm = async (event) => {
    event.preventDefault();

    const formdata = new FormData(event.target);
    const json = {};
    formdata.forEach(function (value, prop) {
      json[prop] = value;
    });

    let body = {
      financial_report: {
        historical_starting: json["financial_report[historical_starting]"],
        historical_ending: json["financial_report[historical_ending]"],
        forecast_starting: json["financial_report[forecast_starting]"],
        forecast_ending: json["financial_report[forecast_ending]"],
      },
    };

    const csrftoken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    document.getElementById("financial-report-csrftoken").value = csrftoken;

    await fetch("/api/v1/financial_reports", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        document.querySelectorAll("input[type=submit]")[0].disabled = false;
        if (Array.isArray(response)) {
          if (response.length == 3) {
            response.forEach((msg) => {
              props.formSubmit();
            });
          } else {
            alert(response);
          }
        } else {
          document.getElementById("financial_report_id").value = response;
          props.formSubmit();
        }
      })
      .catch((error) => {
        document.querySelectorAll("input[type=submit]")[0].disabled = false;
      });
  };

  const getPublished = () => {
    return props.published !== undefined ? props.published : false;
  };

  const publishReport = async () => {
    event.preventDefault();

    let body = {
      financial_report: {
        published: document.getElementById("published").checked,
      },
    };

    const csrftoken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    document.getElementById("financial-report-csrftoken").value = csrftoken;

    await fetch("/api/v1/financial_reports/publish", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {})
      .catch((error) => {});
  };

  return (
    <div>
      <div className="df tools-row">
        <button className="btn btn-dark margin-auto">BACK TO REPORT</button>
        <div className="light-gray-background horizontal-padding df space-around">
          <button className="btn tool-button">FINANCIAL TOOLS</button>
          <button className="btn tool-button active">Financial Analysis</button>
          <button className="btn tool-button">Stress Analysis</button>
          <button className="btn tool-button">Payment Schedule</button>
          <button className="btn tool-button">Capitalization Table</button>
          <button className="btn tool-button">Debt Schedule</button>
        </div>
      </div>

      <div className="financial-analysis-row">
        <div className="financial-analysis-title">
          <h5>
            <strong>Financial</strong>
          </h5>
          <h5>
            <strong>Analysis</strong>
          </h5>
        </div>

        <form
          action="/financial_reports"
          acceptCharset="UTF-8"
          method="post"
          onSubmit={sendForm}
          className="financial-report-form"
        >
          <input
            type="hidden"
            name="authenticity_token"
            id="financial-report-csrftoken"
          />

          <YearInputs
            name={"historical"}
            startingOptions={props.options.historical_starting}
            endingOptions={props.options.historical_ending}
          />
          <YearInputs
            name={"forecast"}
            startingOptions={props.options.forecast_starting}
            endingOptions={props.options.forecast_ending}
          />

          <div className="financial-analysis-buttons form-group">
            <input
              type="submit"
              name="commit"
              value="Generate"
              className="btn primary-button button-submit mr-2 px-4"
              data-disable-with="Creating"
              data-turbolinks="false"
            />
          </div>
        </form>

        <div className="financial-analysis-extra">
          <div className="publish-report">
            <strong>Publish on "Credit Memorandum" Report</strong>
            <div className="custom-control custom-switch left-padding">
              <input
                id="publish"
                className="custom-control-input required-toggle"
                type="checkbox"
                value="true"
                defaultChecked="checked"
              />
              <label className="custom-control-label" htmlFor="publish"></label>
            </div>
          </div>
          <div className="publish-report-save">
            <button className="btn btn-primary">Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableForm;
