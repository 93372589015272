$(document).on('turbolinks:load', function() {
    document.querySelectorAll('#toggle-width-button').forEach(button => {
        button.addEventListener('click', function() {
            // Find the rich-text-area within the same rich-text-container
            const richTextArea = this.parentElement.querySelector('.rich-text-area');
            if (richTextArea.style.width === "134%") {
                richTextArea.style.width = "100%";
                richTextArea.style.minHeight = "";
                this.innerHTML = '<i id="expand-icon" class="fas fa-expand" style="color: gray;"></i>';
            } else {
                richTextArea.style.width = "134%";
                richTextArea.style.minHeight = "800px";
                this.innerHTML = '<i id="collapse-icon" class="fas fa-compress" style="color: gray;"></i>';
            }
        });
    });
});
