// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")

import "bootstrap"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.jQuery = $;
window.$ = $;

require("trix")
require("@rails/actiontext")
import Trix from 'trix'
window.Trix = Trix;

require("cocoon-js")

require("select2")

import "@fortawesome/fontawesome-free/js/all";
import "chartkick/chart.js"

import Sortable from 'sortablejs';
window.Sortable = Sortable;

require("packs/custom/user.js")
require("packs/custom/users_form.js")
require("packs/custom/common.js")
require("packs/custom/share.js")
require("packs/custom/new.js")
require("packs/custom/new_report.js")
require("packs/custom/sidebar.js")
require("packs/custom/tips.js")
require("packs/custom/role.js")
require("packs/custom/currency.js")
require("packs/custom/company.js")
require("packs/custom/evf.js")
require("packs/custom/askchatgpt.js")
require("packs/custom/deal_amortization.js")
require("packs/custom/text_area_field.js")

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
