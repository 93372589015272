import React, { useState, useEffect } from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";

import TableForm from "components/TableForm";
import Report from "components/Report";

const FinancialReports = (props) => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    fetchState();
  }, []);

  async function fetchState() {
    const columns_fetch = await fetch("/api/v1/financial_reports");
    columns_fetch.json().then((response) => {
      setReports(response);
    });
  }

  const isPublished = () => {
    return reports[0] !== undefined ? reports[0].published : false;
  };

  return (
    <div className="financial-report-containers">
      <TableForm
        published={isPublished()}
        options={props.options}
        formSubmit={fetchState.bind(this)}
      />

      {reports.map((report) => (
        <Report
          id={report.id}
          key={report.id}
          fetchTable={fetchState.bind(this)}
        />
      ))}
    </div>
  );
};

export default FinancialReports;
