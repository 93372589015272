export const chapters = {
  "Overview of Lease / Loan & Basic Company Details.": [],
  "Executive Summary – Borrower & Company Background.": [],
  "Company Management Overview.": [],
  "Ownership & Company Organization Overview.": [],
  "Industry Overview.": [],
  "Customer Overview.": [],
  "Product Overview.": [],
  "Competitor Overview.": [],
  "Special Market / Industry Dynamics Worthy of Note.": [],
  "Financial Analysis – Income Statement Overview.":[],
  "Performance Ratios & Debt Service Ability.":[],
  "Forecast – Income Statement (Revenue, Margin, EBITDA & Net Income Growth).":[],
  "Stress Test Scenarios – Revenue, Costs, Margins, External Events.":[],
  "Balance Sheet / Cash Flow Statement Overview.":[],
  "Other Credit Enhancements For Consideration.":[],
  "Guarantor Analysis":[],
  "Asset & Equipment To Be Financed Overview.":[],
  "Critical Use Analysis: Asset Link To Revenue Generation or Costs Savings":[],
  "Asset Valuation & Cost: Appraisal, Useful Life Analysis":[],
  "Collateral Valuation Coverage Analysis.":[],
  "Risk & Mitigants.":[],
  "Summary Statement.":[],
  "Deal Documentation Basics.":[],
  "Deal Amount & Structure.":[],
  "Industry / Assets Type.":[],
  "Deal Structure / Product Type.":[],
  "Special Contract Features.":[],
  "Other.":[],
  "Originator / Syndicating Firm.":[],
};
chapters["Executive Summary – Borrower & Company Background."][2] ={
  question:`Please provide a brief overview and background of the Company including: \n 1.Company history, what industry(s) do they operate in?\n 2.What type of products or services do they provide?\n 3.How are their offerings positioned in the marketplace you compete in?\n 4.Where are their locations?\n 5.How does the company generate revenue and profit?\n 6.Please describe how the company has grown, in size of employees, market share and financially.\n 7.What is the company’s growth outlook?`,
  sample_ans:`ABC Healthcare Inc. was formed in 1984 . The Company has corporate offices, manufacturing facilities, and distribution facilities located throughout the US, Canada, the UK, France, Germany, China, Hong Kong, Romania, Australia, the Netherlands and India. Products are marketed to customers located throughout the US, Europe, Canada, Mexico, South America, Latin America, the Middle East and Asia. The Company employs approximately 2,400 people worldwide. FY17 revenue totaled $848MM, with North America accounting for 65% of sales, Europe 31%, and the rest of the world 4%.
  Durable medical equipment is non-disposable medical equipment used in the course of treatment and home care. Products offered include various types of mobility, lifestyle and therapeutic items as listed in Exhibit 1 below. In aggregate the Company has over 3,500 cataloged products. The Company serves a customer base of 15,000+ dealers, home healthcare providers, long-term care providers, healthcare distributors, independent and chain retailers, and e-commerce companies. Service offerings include leasing programs, equipment repairs and servicing, and delivery services.
  ABC Healthcare Inc. was formed in 1984 . The Company has corporate offices, manufacturing facilities, and distribution facilities located throughout the US, Canada, theUK, France, Germany, China, Hong Kong, Romania, Australia, the Netherlands and India. Products are marketed to customers located throughout the US, Europe, Canada, Mexico, South America, Latin America, the Middle East and Asia. The Company employs approximately 2,400 people worldwide. FY17 revenue totaled $848MM, with North America accounting for 65% of sales, Europe 31%, and the rest of the world 4%.
  `,
  context:"context1",
}
chapters["Executive Summary – Borrower & Company Background."][5]={
  question:`What is the company looking to finance (e.g type of asset / equipment)? New? Used? Please describe and provide make, model numbers and a picture.`,
  sample_ans:`Durable medical equipment is non-disposable medical equipment used in the course of treatment and home care. Products offered include various types of mobility, lifestyle and therapeutic items as listed in Exhibit 1 below. In aggregate the Company has over 3,500 cataloged products. The Company serves a customer base of 15,000+ dealers, home healthcare providers, long-term care providers, healthcare distributors, independent and chain retailers, and e-commerce companies. Service offerings include leasing programs, equipment repairs and servicing, and delivery services.
  `,
  context:"equipment_context",
}
chapters["Executive Summary – Borrower & Company Background."][8]={
  question:`How will the asset be used? Please describe how the asset being financed will work within the company's business? Is it revenue generating and critical to their business?`,
  sample_ans:`The aging of the global population will generate increased demand for DDV's product offerings, which are predominantly mobility aids, beds, respiratory aids, and bath and safety devices. Each of these products is often used by patients aged 65 and older. The aging of the global population is expected to provide a tailwind to revenues. Growth prospects should be further bolstered by an ongoing shift toward value-based products and services and a shift in care toward lower-cost settings, including home health services.
  `,
  context:"equipment_context",
}
chapters["Company Management Overview."][1] = {
  question:`Please provide an overview of the company’s leadership and provide the biographies of the CEO, President, CFO, head of sales and head of technology if applicable.`,
  // question:`Please provide a brief overview and background of the Company including:\n1.Company history, what industry(s) do they operate in?\n2.What type of products or services do they provide?\n3.How are their offerings positioned in the marketplace you compete in?\n4.Where are their locations?\n5.How does the company generate revenue and profit?\n6.Please describe how the company has grown, in size of employees, market share and financially.\n7.What is the company’s growth outlook?\n`,
  sample_ans:`
  The management team has evolved since XYZ assumed control with the addition of a new Chief Executive Officer, Chief Financial Officer, and Executive Vice President Supply Chain. Management has extensive experience across all functional areas as well as the healthcare industry.
  
  John Doe , Chief Executive Officer, joined the Company in December 2017. Mr. Doe has over 30 years of experience in both manufacturing and service businesses, including healthcare, technology, heavy industrial and electric utilities. During his 25 year career with General Electric, Mr. Doe held leadership roles in various business units including Healthcare, Energy and Industrial Systems. Mr. Gilligan is known for building and leading teams around a clear strategy, developing talent and delivering results. He holds a BS in Mechanical Engineering from Bucknell University, and an MBA from the University of Pennsylvania Wharton School.
  
  Jane Jones President, Chief Financial Officer, joined in 2017 bringing 25 years of global financial experience in Finance, Accounting and Human Resources and Legal departments. Previously, Jones was Chief Financial Officer of UX Systems, a KKK portfolio company. She also served as CFO at KKJ Holdings, a $1Bn global company. Previously, she spent 14 years at CDF Corporation in a variety of executive financial positions. . Ms. Jones began her career at Ernst & Young.
  
  James Murphy , Executive Vice President, Global Supply Chain, joined in April 2018. Mr. Murphy has over 28 years of experience in Global Supply Chain Operations and Manufacturing across a variety of industry-leading companies including Toyota, Callaway Golf, Targus, Wilton Brands, and others. He has held roles from individual contributor through COO and managed functional teams including Sourcing, Materials Planning, Procurement, Manufacturing, Quality, Engineering and Compliance
  `,
  context:"industry_context",
}
chapters["Ownership & Company Organization Overview."][1] ={
  question:"Please provide an overview of the company’s ownership structure.",
  sample_ans:`XYZ Corp is owner by the Smith Companies 100%. The Company reports to a board of directors. Josh X is the CEO and possesses deep industry experience., Fred K is the CFO and Laura B, COO, heads up sales and distribution. See Organizational chart in Exhibit 2 below`,
  context:"context1",
}
chapters["Industry Overview."][2] = {
  question:`Please provide an overview of the company’s industry including: \n 1.Size and market description (US, global etc), growth rate.`,
  sample_ans:`The outlook for both the global and US durable medical equipment industry is positive. Globally the durable medical equipment market is anticipated to display robust growth represented by a CAGR of 8.3%, increasing from $112Bn in 2016, to $229Bn by 2025. North America holds the largest market share, followed by Europe. Asia-Pacific is expected to grow at high rate in the coming years due to increasing R&D expenditures by governments and private companies in healthcare. China, Japan, and India have witnessed economic growth, which has gradually led to improvements in healthcare infrastructure.
  In the US, the durable equipment market is expected to reach $70.8Bn by 2025, at a 6.1% CAGR. The US market was valued at $46.8Bn in 2018. Primary growth drivers include an aging and growing population, a rise in chronic diseases and disorders requiring long-term care, and patient’s preference for long-term home based care.`,
  context:"industry_context",
}
chapters["Industry Overview."][6] = {
  question:`What are the growth drivers in the company’s industry? \n 1.Please indicate what the company’s industry recent history has been and the growth outlook over the next 5 years.`,
  sample_ans:`Primary growth drivers include an aging and growing population, a rise in chronic diseases and disorders requiring long-term care, and patient’s preference for long-term home based care.`,
  context:"industry_context",
}
chapters[ "Industry Overview."][9] = {
  question:`1.Are there any unique attributes to the industry that are notable?\n 2.Is the industry affected by Tariffs? If so, what is the impact over the lease / loan period?\n3.Does the industry rely on any certain elements/drivers/legislative elements worthy of note?\n4.Are there industry regulations that affect the company’s business and need regular monitoring?\n5.What are the barriers to entry?\n`,
  sample_ans:` `,
  context:"industry_context",
}
chapters["Customer Overview."][2] ={
  question: `Please provide an overview of the company’s customer base.\n 1.How many customers does the company have?\n 2.Please provide a breakout of revenue by customer (customer mix), including historical mix and current mix.\n 3.Will there be any changes to the customer base or mix during the lease / loan term?\n 4.Please discuss any key customer relationships, dynamics or concentrations.`,
  sample_ans:`Our customers are made up of virtually any and all healthcare providers, globally, who use any type of medical equipment. As the aging population and healthcare needs grow, so does our customer base.`,
  context:"industry_context",
}
chapters["Product Overview."][2] = {
  question:`Please provide an overview of the company’s products and services.\n 1.What do they do?\n2.How are they marketed?\n3.How are they used by customers?\n4.How do they stack-up against other similar products or services in the marketplace?`,
  sample_ans:`We source and distribute a wide variety of healthcare equipment form our global distribution centers in the US and around the world.`,
  context:"context1",
}
chapters["Competitor Overview."][2] = {
  question:`Please provide an overview of the company’s competitors and the competitive forces that the company faces in their market.\n1.Please provide a brief description of the company’s top 5-10 competitors.\n2.Are there a few large players or many smaller ones?\n3.How many are there in the industry? Please rank them.\n4.What % of annual industry revenues do the top 10% make up? The top 50%?\n 5.Please describe the company’s competitive advantage.\n6.How does the company compete?\n7.Where does the company rank among its competitors?\n8.Do you expect this is going to change over the next 5 years?`,
  sample_ans:`The global durable medical equipment market is reasonably fragmented due to a strong presence of several key players including ABC . The market is also highly competitive, including a large number of competitors of similar or greater size that compete directly with the Company with similar products: highlighted below.
      BBB Corporation (NYSE: ….) is a leading global manufacturer of and distributor of medical equipment used in non-acute care settings. Its products are primarily sold to home medical equipment providers, residential living operators, distributors and government health services in North America, Europe, and Asia/Pacific. Products include wheelchairs, custom manual wheelchairs, crutches, walkers, homecare beds, and respiratory therapy products. The company is based out of Bangor, ME, and has approximately 4,200 employees globally. FY17 revenue totaled $966MM, with the majority of sales (55%) derived from Europe.`,
  context:"context1",
    }
chapters["Special Market / Industry Dynamics Worthy of Note."][1] = {
  question:`Please describe any special elements or dynamics to the company’s industry (e.g. political vulnerabilities, technological breakthroughs or obsolescence, trade barriers, foreign competitors or elements, labor shortages etc).`,
  sample_ans:` `,
  context:"context1",
}
chapters["Guarantor Analysis"][2]={
  question:`Please provide a brief overview and background of the Company including: \n 1.Company history, what industry(s) do they operate in?\n 2.What type of products or services do they provide?\n 3.How are their offerings positioned in the marketplace you compete in?\n 4.Where are their locations?\n 5.How does the company generate revenue and profit?\n 6.Please describe how the company has grown, in size of employees, market share and financially.\n 7.What is the company’s growth outlook?`,
  sample_ans:`ABC Healthcare Inc. was formed in 1984 . The Company has corporate offices, manufacturing facilities, and distribution facilities located throughout the US, Canada, the UK, France, Germany, China, Hong Kong, Romania, Australia, the Netherlands and India. Products are marketed to customers located throughout the US, Europe, Canada, Mexico, South America, Latin America, the Middle East and Asia. The Company employs approximately 2,400 people worldwide. FY17 revenue totaled $848MM, with North America accounting for 65% of sales, Europe 31%, and the rest of the world 4%.
    Durable medical equipment is non-disposable medical equipment used in the course of treatment and home care. Products offered include various types of mobility, lifestyle and therapeutic items as listed in Exhibit 1 below. In aggregate the Company has over 3,500 cataloged products. The Company serves a customer base of 15,000+ dealers, home healthcare providers, long-term care providers, healthcare distributors, independent and chain retailers, and e-commerce companies. Service offerings include leasing programs, equipment repairs and servicing, and delivery services.
    ABC Healthcare Inc. was formed in 1984 . The Company has corporate offices, manufacturing facilities, and distribution facilities located throughout the US, Canada, theUK, France, Germany, China, Hong Kong, Romania, Australia, the Netherlands and India. Products are marketed to customers located throughout the US, Europe, Canada, Mexico, South America, Latin America, the Middle East and Asia. The Company employs approximately 2,400 people worldwide. FY17 revenue totaled $848MM, with North America accounting for 65% of sales, Europe 31%, and the rest of the world 4%.`,
  context:"guarantor_context",
}
chapters["Asset & Equipment To Be Financed Overview."][1] = {
  question:`Please provide an overview of the Asset & Equipment To Be Financed:`,
  sample_ans:`collateral equipment, a Johnson Solutions racking system, is business essential to the Company as the system will be utilized in its primary distribution center in Atlanta, GA. The useful life of the equipment can be up to twenty (20) years, if maintained. In addition, there is low risk that the equipment will become obsolete as the design of racking systems have remained relatively unchanged over the past few decades. In addition, racking systems are now utilized in retail stores including Home Depot, Lowes, and warehouse clubs such as Costco and BJ’s. Collateral equipment for the UCC Filing will be carved out and specifically identified.
  Racking equipment is supplied by material handling firm Johnson Solutions. The company offers fleet solutions, warehouse designs, conveyors and sortation, and other related products. The company is located in Austin TX, California and serves customers throughout the US. See Exhibit A for a complete list of equipment and cost.
  The vendor, Johnson Solutions, provides a remarketing service for used racking equipment. A representative from Johnson Solutions provided a conservative estimate, noting the equipment could be resold five years from installation at 29% of the original equipment cost, which includes the cost to disassemble the equipment and prepare it for shipment. See Exhibit 4 below.`,
  context:"equipment_context",
}
chapters["Critical Use Analysis: Asset Link To Revenue Generation or Costs Savings"][1]={
  question:`Please describe and or discuss the critical use of this asset / equipment with respect to the company’s ability to conduct business. Please describe how this asset / equipment is linked to either revenue generation or costs savings.`,
  sample_ans:`The vendor, Johnson Solutions, provides a remarketing service for used racking equipment. A representative from Johnson Solutions provided a conservative estimate, noting the equipment could be resold five years from installation at 29% of the original equipment cost, which includes the cost to disassemble the equipment and prepare it for shipment. See Exhibit 4 below.`,
  context:"equipment_context",
}
chapters["Asset Valuation & Cost: Appraisal, Useful Life Analysis"][1]={
  question:`1.What is the useful life of the asset / equipment?. \n 2.Does it require significant or periodic maintenance?. \n 3.Does the company own the property where the asset will be located and utilized? If not, does the company lease the location / building / space? Is there anything impeding / limiting access to the equipment?. \n 4.Will the asset / equipment be in one location? Moved around? Between states? Outside the United States? \n 5.Please attach and / or describe an appraisal of the company's asset / equipment.`,
  // question:"1.Please attach and / or describe an appraisal of the company's asset / equipment.\n 2.What is the useful life of the asset / equipment?. \n 3.Does it require significant or periodic maintenance?. \n 4.Does the company own the property where the asset will be located and utilized? If not, does the company lease the location / building / space? Is there anything impeding / limiting access to the equipment?. \n 5.Will the asset / equipment be in one location? Moved around? Between states? Outside the United States?",
  sample_ans:`The useful life of the equipment can be up to twenty (20) years, if maintained. In addition, there is low risk that the equipment will become obsolete as the design of racking systems have remained relatively unchanged over the past few decades. In addition, racking systems are now utilized in retail stores including Home Depot, Lowes, and warehouse clubs such as Costco and BJ’s. Collateral equipment for the UCC Filing will be carved out and specifically identified. Below is a comparison of the depreciated value of the equipment over its useful life as compared to the outstanding amount of the loan / lease. As exhibited below, the asset provides excess collateral for the lender after a short period of time and last 20 years.`,
  context:"equipment_context",
}
chapters["Risk & Mitigants."][1] ={
  question: `Cash flow is insufficient to meet debt service or other operational expense needs.`,
  sample_ans:`Strategic initiatives around stabilizing the platform initiated over the past 18 months support margin expansion and have improved cash flow as evidenced by positive cash flow generation (EBITDA) for the nine months ended 9/30/18.
      Reasonable forecasts exhibit adequate debt service coverage at or above 1:1 even when under stress.
      Letter of credit (50%) and parent guarantee provide significant cash cushion.
      We are a key player in a growing industry with a positive outlook.
      Strong demand for products and services driven by favorable underlying demographics and industry trends.
      Reorganization underway in Europe will further drive improvements in operating efficiency and cash flow.
      Strong new management team with significant industry knowledge and turnaround experience has brought a disciplined approach to operations driving cash generation.`,
  context:"context1",
}
chapters["Risk & Mitigants."][3] = {
  question:`High Leverage (Definitions: Total debt to equity 5x or higher)`,
  sample_ans:`Higher margins stemming from efficiency gains coupled with relatively light CAPEX will generate free cash flow available for additional debt reduction and capital expansion.
      Our PE owner has the capacity to provide additional capital, if required.`,
  context:"context1",
}
chapters["Risk & Mitigants."][5] = {
  question:`what are the Risk & Mitigants for the e.g. loss of large customer, increased cost of raw materials, if in healthcare, declining insurance reimbursement rates etc`,
  sample_ans:`As an industry leader we have significant purchasing power with our suppliers.
      Our new leadership team has a disciplined approach to managing all aspects of pricing and we have brought the pricing manager role down to the business line.
      Our corporate wide focus on continuous improvement promotes a culture of innovation and efficiency.
      Industry trends point to growth in home health services (rather than hospitalization), which is a key component of our future growth.`,
  context:"context1",
}
chapters["Risk & Mitigants."][2] = {
  question:`1.Does the company have high debt or leverage? \n 2.Does the company have limited cash flow to cover operating expenses or debt payments? \n 3.Is the company’s cost of debt increasing because interest rates are rising? \n 4.Does the company have their line of credit or working capital facility expiring in the next few years? \n 5.Is the company’s line of credit renewal not completed yet? \n 6.Are inventory levels too high? \n 7.Are payables increasing? \n 8.Are account receivables increasing? \n 9.Does the company experience seasonality in their revenue cycle? \n 10.Is the company’s cashflow weakening?`,
  sample_ans:``,
  context:"context3",
}
chapters["Risk & Mitigants."][9] = {
  question:`1.Are the company’s operating margins weakening or is there pressure on margins? \n 2.Is revenue decreasing? Have they lost an important customer contract? \n 3.Has the company lost any customers recently? \n 4.Are production costs increasing? \n 5.Did the company have any projects delayed? \n 6.Does the company utilize unionized workers? Have there been any recent union work strikes? \n 7.Is the company counting on certain growth events like domestic or international expansive to generate business plan revenues? \n 8.Is the company competitive in their market? \n 9.Is competition dominant in their market? \n 10.Is their product unique? \n 11.Does the company have a high degree of customer concentration?`,
  sample_ans:``,
  context:"context3",
}
chapters["Risk & Mitigants."][2] = {
  question:`1.Does the company have high debt or leverage? \n 2.Does the company have limited cash flow to cover operating expenses or debt payments? \n 3.Is the company’s cost of debt increasing because interest rates are rising? \n 4.Does the company have their line of credit or working capital facility expiring in the next few years? \n 5.Is the company’s line of credit renewal not completed yet? \n 6.Are inventory levels too high? \n 7.Are payables increasing? \n 8.Are account receivables increasing? \n 9.Does the company experience seasonality in their revenue cycle? \n 10.Is the company’s cashflow weakening?`,
  sample_ans:``,
  context:"context3",
}
chapters["Risk & Mitigants."][16] = {
  question:`1.Does the company have Government contract vulnerability? Are their contracts subject to appropriations risk? \n 2.Is the company exposed to regulatory risk? \n 3.Has the company had any important leadership turnover or changes? \n 4.Does the company suffer from high employee attrition rates? \n 5.Has the company recently emerged from bankruptcy? \n 6.Has the company recently acquired another company, or recently been acquired, and have any integrations issues? \n 7.Is the company exposed to unique market risks? \n 8.Is the company exposed to technology risk? \n 9.Is the company exposed to political risk?`,
  sample_ans:``,
  context:"context3",
}


chapters['Other.'][1]={
  question:`Are there any other unique or unusual elements to this transaction?`,
  sample_ans:``,
  context:"context3",
}
export const credit_memorandum_chapters = {...chapters}

export const credit_approval_memorandum_chapters = {...chapters,
"Industrial Overview.":[],
"Industry Overview.":[]
}

credit_approval_memorandum_chapters["Industrial Overview."][2] = {
question:`Please provide an overview of the company’s industry including: \n 1.Size and market description (US, global etc), growth rate.`,
sample_ans:`The outlook for both the global and US durable medical equipment industry is positive. Globally the durable medical equipment market is anticipated to display robust growth represented by a CAGR of 8.3%, increasing from $112Bn in 2016, to $229Bn by 2025. North America holds the largest market share, followed by Europe. Asia-Pacific is expected to grow at high rate in the coming years due to increasing R&D expenditures by governments and private companies in healthcare. China, Japan, and India have witnessed economic growth, which has gradually led to improvements in healthcare infrastructure.
In the US, the durable equipment market is expected to reach $70.8Bn by 2025, at a 6.1% CAGR. The US market was valued at $46.8Bn in 2018. Primary growth drivers include an aging and growing population, a rise in chronic diseases and disorders requiring long-term care, and patient’s preference for long-term home based care.`,
  context:"context1",
}
credit_approval_memorandum_chapters["Industrial Overview."][6] = {
question:`What are the growth drivers in the company’s industry? \n 1.Please indicate what the company’s industry recent history has been and the growth outlook over the next 5 years.`,
sample_ans:`Primary growth drivers include an aging and growing population, a rise in chronic diseases and disorders requiring long-term care, and patient’s preference for long-term home based care.`,
  context:"context1",
}
credit_approval_memorandum_chapters[ "Industrial Overview."][9] = {
question:`1.Are there any unique attributes to the industry that are notable?\n 2.Is the industry affected by Tariffs? If so, what is the impact over the lease / loan period?\n3.Does the industry rely on any certain elements/drivers/legislative elements worthy of note?\n4.Are there industry regulations that affect the company’s business and need regular monitoring?\n5.What are the barriers to entry?\n`,
sample_ans:` `,
  context:"context1",
}
credit_approval_memorandum_chapters["Industry Overview."][2] = {
question:`Please provide an overview of the company’s industry including: \n 1.Size and market description (US, global etc), growth rate.`,
sample_ans:`The outlook for both the global and US durable medical equipment industry is positive. Globally the durable medical equipment market is anticipated to display robust growth represented by a CAGR of 8.3%, increasing from $112Bn in 2016, to $229Bn by 2025. North America holds the largest market share, followed by Europe. Asia-Pacific is expected to grow at high rate in the coming years due to increasing R&D expenditures by governments and private companies in healthcare. China, Japan, and India have witnessed economic growth, which has gradually led to improvements in healthcare infrastructure.
In the US, the durable equipment market is expected to reach $70.8Bn by 2025, at a 6.1% CAGR. The US market was valued at $46.8Bn in 2018. Primary growth drivers include an aging and growing population, a rise in chronic diseases and disorders requiring long-term care, and patient’s preference for long-term home based care.`,
  context:"industry_context",
}
credit_approval_memorandum_chapters["Industry Overview."][6] = {
question:`What are the growth drivers in the company’s industry? \n 1.Please indicate what the company’s industry recent history has been and the growth outlook over the next 5 years.`,
sample_ans:`Primary growth drivers include an aging and growing population, a rise in chronic diseases and disorders requiring long-term care, and patient’s preference for long-term home based care.`,
  context:"industry_context",
}
credit_approval_memorandum_chapters[ "Industry Overview."][9] = {
question:`1.Are there any unique attributes to the industry that are notable?\n 2.Is the industry affected by Tariffs? If so, what is the impact over the lease / loan period?\n3.Does the industry rely on any certain elements/drivers/legislative elements worthy of note?\n4.Are there industry regulations that affect the company’s business and need regular monitoring?\n5.What are the barriers to entry?\n`,
sample_ans:` `,
  context:"industry_context",
}
credit_approval_memorandum_chapters["Risk & Mitigants."][2] = {
question:`1.Does the company have high debt or leverage? \n 2.Does the company have limited cash flow to cover operating expenses or debt payments? \n 3.Is the company’s cost of debt increasing because interest rates are rising? \n 4.Does the company have their line of credit or working capital facility expiring in the next few years? \n 5.Is the company’s line of credit renewal not completed yet? \n 6.Are inventory levels too high? \n 7.Are payables increasing? \n 8.Are account receivables increasing? \n 9.Does the company experience seasonality in their revenue cycle? \n 10.Is the company’s cashflow weakening?`,
sample_ans:``,
context:"context3",
}
credit_approval_memorandum_chapters["Risk & Mitigants."][9] = {
question:`1.Are the company’s operating margins weakening or is there pressure on margins? \n 2.Is revenue decreasing? Have they lost an important customer contract? \n 3.Has the company lost any customers recently? \n 4.Are production costs increasing? \n 5.Did the company have any projects delayed? \n 6.Does the company utilize unionized workers? Have there been any recent union work strikes? \n 7.Is the company counting on certain growth events like domestic or international expansive to generate business plan revenues? \n 8.Is the company competitive in their market? \n 9.Is competition dominant in their market? \n 10.Is their product unique? \n 11.Does the company have a high degree of customer concentration?`,
sample_ans:``,
context:"context3",
}
credit_approval_memorandum_chapters["Risk & Mitigants."][16] = {
question:`1.Does the company have Government contract vulnerability? Are their contracts subject to appropriations risk? \n 2.Is the company exposed to regulatory risk? \n 3.Has the company had any important leadership turnover or changes? \n 4.Does the company suffer from high employee attrition rates? \n 5.Has the company recently emerged from bankruptcy? \n 6.Has the company recently acquired another company, or recently been acquired, and have any integrations issues? \n 7.Is the company exposed to unique market risks? \n 8.Is the company exposed to technology risk? \n 9.Is the company exposed to political risk?`,
sample_ans:``,
context:"context3",
}




export function generateMessageContext(company_name,question_data, prompt_data) {
  prompt_data = JSON.parse(prompt_data)
  question_data = JSON.parse(question_data)
  const question=question_data?.question, sample_ans=question_data?.sample_ans, context=question_data?.context
  const guarantor_name=prompt_data?.guarantor;
  const industry=prompt_data?.industry;
  const equipment_asset=prompt_data?.equipment_asset;

const context1 = [
  {
    role: "system",
    content: `
      You are a seasoned credit manager, equipped with expertise in credit analysis,
      corporate finance, economics, accounting, investments, and
      equipment financing. Your task is to aid in conducting an initial
      assessment of a potential credit opportunity for our company.
      Maintain a professional yet approachable tone in your communication to ensure clarity.
      and return the summary like the example given by the user`,
  },
  {
    role: "user",
    content: `
      My company name is ${company_name}.
      You will be provided with text delimited by triple quotes which
      contains questions related for ${company_name} and you have to answer the questions in brief.
        search from the internet to the provided question return that answer like the sample answer given below else ask questions in the below manner
      - ask one question and wait for me to answer that question then proceed with the next one.
      \"\"\"${question}\"\"\"
      A sample answer for the given questions is given with text delimited by triple asterisk for your reference which is not related to my company.
      Strictly do not use the information in the sample answer to answer the questions.
      ***${sample_ans}***`,
  },
];
const context2 = [ 
  {'role':'system', 'content':`
    You are a seasoned credit manager, equipped with expertise in credit analysis,
    corporate finance, economics, accounting, investments, and
    equipment financing. Your task is to aid in conducting an initial
    assessment of a potential credit opportunity for our company.
    Maintain a professional yet approachable tone in your communication to ensure clarity.
    return the summary like the example given by the user.`
  },
  {'role': 'user', 'content': `
    My company name is ${company_name}.
    You will be provided with text delimited by triple quotes which
    contains questions related for ${company_name} and you have to answer the questions in brief.
    If you already have answer to the provided question return that answer like the sample answer given below else ask  questions in the below manner
    - ask one question and wait for me to answer that question then proceed with the next one.
    \"\"\"${question}\"\"\"
    A sample answer for the given questions is given with text delimited by triple asterisk for your reference which is not related to my company.
    Strictly do not use the information in the sample answer to answer the questions.
    ***${sample_ans}***`
  }
]
const context3 = [ {'role':'system', 'content':`
You are a seasoned credit manager, equipped with expertise in credit analysis,
corporate finance, economics, accounting, investments, and
equipment financing. Your task is to aid in conducting an initial
assessment of a potential credit opportunity for our company.
Maintain a professional yet approachable tone in your communication to ensure clarity.
return the summary of the conversation.`
},
{'role': 'user', 'content': `
  My company name is ${company_name}.
  You will be provided with text delimited by triple quotes which
  contains questions related for ${company_name} and you have to answer the questions in brief.
  If you already have answer to the provided question return that answer else ask questions in the below manner
  - ask one question and wait for me to answer that question then proceed with the next one.
  \"\"\"${question}\"\"\"
  `

}
]
const industry_context = [ 
  {'role':'system', 'content':`
    You are a seasoned credit manager, equipped with expertise in credit analysis,
    corporate finance, economics, accounting, investments, and
    equipment financing. Your task is to aid in conducting an initial
    assessment of a potential credit opportunity for our company.
    Maintain a professional yet approachable tone in your communication to ensure clarity.
    return the summary like the example given by the user.`
  },
  {'role': 'user', 'content': `
    My company name is ${company_name} and we are operating in ${industry} industry.
    You will be provided with text delimited by triple quotes which
    contains questions related for ${company_name} and you have to answer the questions in brief.
    search from the internet to the provided question return that answer like the sample answer given below
    \"\"\"${question}\"\"\".
    A sample answer for the given questions is given with text delimited by triple asterisk for your reference which is not related to my company.
    Strictly do not use the information in the sample answer to answer the questions.
    ***${sample_ans}***`
  }
]
const equipment_context = [ 
  {'role':'system', 'content':`
    You are a seasoned credit manager, equipped with expertise in credit analysis,
    corporate finance, economics, accounting, investments, and
    equipment financing. Your task is to aid in conducting an initial
    assessment of a potential credit opportunity for our company.
    Maintain a professional yet approachable tone in your communication to ensure clarity.
    return the summary like the example given by the user.`
  },
  {'role': 'user', 'content': `
    My company name is ${company_name}.
    You will be provided with text delimited by triple quotes which
    contains questions related for ${company_name} and you have to answer the questions in brief.
    search from the internet to the provided question return that answer like the sample answer given below else ask questions in the below manner
    \"\"\"${question}\"\"\".
    And the asset / equipement to be financed is ${equipment_asset}.
    A sample answer for the given questions is given with text delimited by triple asterisk for your reference which is not related to my company.
    Strictly do not use the information in the sample answer to answer the questions.
    ***${sample_ans}***`
  }
]

const guarantor_context = [
  {
    role: "system",
    content: `
      You are a seasoned credit manager, equipped with expertise in credit analysis,
      corporate finance, economics, accounting, investments, and
      equipment financing. Your task is to aid in conducting an initial
      assessment of a potential credit opportunity for our company.
      Maintain a professional yet approachable tone in your communication to ensure clarity.
      and return the summary like the example given by the user`,
  },
  {
    role: "user",
    content: `
      My company name is ${guarantor_name}.
      You will be provided with text delimited by triple quotes which
      contains questions related for ${guarantor_name} and you have to answer the questions in brief.
      search from the internet to the provided question return that answer like the sample answer given below else ask questions in the below manner
      - ask one question and wait for me to answer that question then proceed with the next one.      
      \"\"\"${question}\"\"\"
      A sample answer for the given questions is given with text delimited by triple asterisk for your reference which is not related to my company.
      Strictly do not use the information in the sample answer to answer the questions.
      ***${sample_ans}***`,
  },
]
switch (context){
  case "context1":
    return context1;
  case "context2":
    return context2;
  case "context3":
    return context3;
  case "industry_context":
    return industry_context;
  case "equipment_context":
    return equipment_context;
  case "guarantor_context":
    return guarantor_context;
  default:
    return context2;
}
}
