$(document).on('turbolinks:load', function() {
  formatCurrency($("input[id='evf_form_quantity']"));
  $("input[id='evf_form_total_currency'], input[id='evf_form_quantity']").on({
      keyup: function() {
        formatCurrency($(this));
      },
      blur: function() { 
        formatCurrency($(this), "blur");
      }
  });
  
  
  $(".percentage_field").on({
    keyup: function() {
      formatPercentage($(this));
    },
    blur: function() { 
      formatPercentage($(this), "blur");
    }
  });


  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  function formatPercentage(input, blur){
    if (!input?.length || input?.val()===""){return;}
    var input_val = input.val();
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = formatNumber(left_side);
      left_side = Number(left_side.replace(/[,]/gi, ''))>100?100:left_side
      right_side = formatNumber(right_side).replace(/[,]/gi, '');
      if (blur === "blur") {
        right_side += "0000";
      }
      right_side = right_side.substring(0, 4);
      input_val = left_side + "." + right_side + "%";
    } else {
      input_val = formatNumber(input_val);
      input_val = Number(input_val.replace(/[,]/gi, ''))>100?100:input_val
      // input_val = input_val + "%";
      
      if (blur === "blur") {
        input_val = formatNumber(input_val)
        input_val += ".0000%";
      }
    }
    input.val(input_val);
    // input?.prop('selectionStart',input_val?.length-1)
    // input?.prop('selectionEnd',input_val?.length-1)
  }


  function formatCurrency(input, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.
    
    // don't validate empty input
    if (!input?.length || input?.val()===""){return;}
    
    // get input value
    var input_val = input.val();
    
    // original length
    var original_len = input_val.length;

    // initial caret position 
    var caret_pos = input.prop("selectionStart");
      
    // check for decimal
    if (input_val.indexOf(".") >= 0) {

      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      var decimal_pos = input_val.indexOf(".");

      // split number by decimal point
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatNumber(left_side);

      // validate right side
      right_side = formatNumber(right_side);
      
      // On blur make sure 2 numbers after decimal
      if (blur === "blur") {
        right_side += "00";
      }
      
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      if(input.attr('id') == 'evf_form_total_currency'){
        input_val = "$" + left_side + "." + right_side;
      }

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatNumber(input_val);
      if(input.attr('id') == 'evf_form_total_currency'){
        input_val = "$" + input_val;
      }
      // final formatting
      if (blur === "blur" && input.attr('id') == 'evf_form_total_currency') {
        input_val += ".00";
      }
    }
    
    // send updated string to input
    input.val(input_val);
    
    // put caret back in the right position
    // var updated_len = input_val.length;
    // caret_pos = updated_len - original_len + caret_pos;
    // input[0].setSelectionRange(caret_pos, caret_pos);
  }
});
