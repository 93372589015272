import React from 'react'
import SelectComponent from 'components/SelectComponent';

const YearInputs = ( props ) => {

	const capitalize = string => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const pluralize = string => {
		return string + 's';
	}

	return (
		<div className={`financial-analysis-${pluralize(props.name)}`}>
  		<strong>{capitalize(props.name)}</strong>
			<div className={pluralize(props.name)}>
				<div className="form-group">
				  <label>Starting Year</label>
				  <SelectComponent 
				  	options={props.startingOptions}
				  	name={`${props.name}_starting`} 
				  	side={'left'} />
				</div>

				<div className="form-group">
				  <label>Ending Year</label>
				  <SelectComponent 
				  	options={props.endingOptions}
				  	name={`${props.name}_ending`} 
				  	side={'right'}/>
				</div>
			</div>
		</div>
	);
}

export default YearInputs;
