import React from 'react'

const SelectComponent = ( props ) => (
	<select 
    name={`financial_report[${props.name}]`}
    className={`form-control select-${props.side}`} >
		{ props.options.map(item => <option key={item} value={item}>{item}</option>) }
	</select>
);

export default SelectComponent;
